import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setIsOpen } from '../../../store/appSlice';
import linkedIn from '../../../assets/linkedin.svg';

import { SButtons, SGrid, SHero } from './Hero.styles';
import ButtonContact from '../../../components/atoms/ButtonContact/ButtonContact';
import Introduction from '../../molecules/Introduction/Introduction';
import MySuperpowers from '../../molecules/MySuperpowers/MySuperpowers';
import Portrait from '../../molecules/Portrait/Portrait';

const Hero: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      setIsOpen(false)
    );
  };

  return (
    <SHero
      id="start"
      onClick={handleClick}
    >
      <SGrid>
        <Portrait />

        <Introduction />

        <MySuperpowers />

        <SButtons>
          <ButtonContact
            href="mailto:a.burburan@gmail.com"
            icon="alternate_email"
            title={t('common.email')}
          />

          <ButtonContact
            alt={t('common.linkedIn')}
            href="https://www.linkedin.com/pub/andreas-burburan/a2/a7/536"
            icon={linkedIn}
            rel="noreferrer"
            target="_blank"
            title={t('common.linkedIn')}
          />
        </SButtons>
      </SGrid>
    </SHero>
  );
};

export default Hero;
