import styled from 'styled-components';

import theme from '../../../theme/theme';

export const SSubtitle = styled.h2`
  color: ${theme.colors.white};
  font-size: ${theme.fontSizes.lion};
  font-weight: 300;
  margin-bottom: ${theme.lengths(6)};

  ${theme.breakpoints.forTabletPortraitUp} {
    font-size: ${theme.fontSizes.hippo};
  }

  ${theme.breakpoints.forTabletLandscapeUp} {
    margin-bottom: ${theme.lengths(7)};
  }

  &:not(:first-of-type) {
    margin-top: 100px;

    ${theme.breakpoints.forDesktopUp} {
      margin-top: 150px;
    }
  }
`;
