const colors = {
  // Blacks
  black: '#000000',
  base: '#000000',

  // Whites
  white: '#ffffff',

  // Colors
  blueDark: '#3e5291',
  blueDarker: '#33406b',
  blueDarkest: '#293661',
  blueLight: '#5c72b9',
  blueLighter: '#6a7dbb',
  blueLightest: '#dee3f1'
};

export default colors;
