const cv = {
  experiences: [
    {
      icon: 'apartment',
      list: [
        {
          list: [
            {
              description: 'Digital Technology Developer, Lead Front-End Developer.',
              image: 'accenturesong.png',
              imageAlt: 'Accenture Song',
              isOngoing: true,
              name: 'Accenture Song',
              timeEnd: '',
              timeStart: '21/05/2018'
            },
            {
              description: 'Graphic design, 3D modelling, front-end development, search engine marketing (SEM), etc. Occasional assignments performed on request.',
              image: 'infab.svg',
              imageAlt: 'Infab',
              isOngoing: false,
              name: 'Infab',
              timeEnd: '15/06/2017',
              timeStart: '25/08/2014'
            },
            {
              description: 'Graphic design, 3D modelling, front-end development, search engine marketing (SEM), etc.',
              image: 'quid.png',
              imageAlt: 'Quid Advertising Agency',
              isOngoing: false,
              name: 'Quid Advertising Agency',
              timeEnd: '22/08/2014',
              timeStart: '01/07/2013'
            },
            {
              description: "Content management, graphic design, image editing. 50&nbsp;%.",
              image: 'ahmans.png',
              imageAlt: 'Åhmans i Åhus',
              isOngoing: false,
              name: 'Åhmans i Åhus',
              timeEnd: '28/06/2013',
              timeStart: '01/03/2013'
            },
            {
              description: "Graphic design, 3D modelling, front-end development, search engine marketing (SEM), etc. 50&nbsp;%.",
              image: 'quid.png',
              imageAlt: 'Quid Advertising Agency',
              isOngoing: false,
              name: 'Quid Advertising Agency',
              timeEnd: '31/05/2013',
              timeStart: '01/03/2013'
            },
            {
              description: 'Graphic design, 3D modelling, front-end development, search engine marketing (SEM), etc.',
              image: 'quid.png',
              imageAlt: 'Quid Advertising Agency',
              isOngoing: false,
              name: 'Quid Advertising Agency',
              timeEnd: '28/02/2013',
              timeStart: '11/04/2011'
            }
          ],
          title: 'Employments'
        },
        {
          list: [
            {
              description: 'Front-end development, UX design.',
              image: 'adapt.png',
              imageAlt: 'ADAPT Centre',
              isOngoing: false,
              name: "ADAPT Centre at Trinity College Dublin: <em>Online Ethics Canvas</em>",
              timeEnd: '25/08/2017',
              timeStart: '03/07/2017'
            },
            {
              description: 'Graphic design, 3D modelling, front-end development, search engine marketing (SEM), etc.',
              image: 'quid.png',
              imageAlt: 'Quid Advertising Agency',
              isOngoing: false,
              name: 'Quid Advertising Agency',
              timeEnd: '08/04/2011',
              timeStart: '28/09/2010'
            },
            /*
            {
              description: 'Assembly, packaging.',
              isOngoing: false,
              name: 'Kristianstad Lego AB',
              timeEnd: '31/08/2010',
              timeStart: '12/04/2010'
            },
             */
            {
              description: 'Graphic design.',
              image: 'ksk.png',
              imageAlt: 'Kristianstad Student Union',
              isOngoing: false,
              name: 'Kristianstad Student Union at Kristianstad University',
              timeEnd: '29/06/2009',
              timeStart: '10/06/2009'
            },
            {
              description: 'Graphic design.',
              image: 'abk.png',
              imageAlt: 'AB Kristianstadsbyggen',
              isOngoing: false,
              name: 'AB Kristianstadsbyggen',
              timeEnd: '05/06/2009',
              timeStart: '26/05/2009'
            },
            /*
            {
              description: "Graphic design, digital printing. 9 weeks in secondary school and 5&ndash;6 months thereafter.",
              isOngoing: false,
              name: 'Orkidén',
              timeEnd: '2008',
              timeStart: '2006'
            },
             */
            {
              description: 'Advertisement production. 1 week.',
              image: 'kb.png',
              imageAlt: 'Kristianstadsbladet',
              isOngoing: false,
              name: 'Kristianstadsbladet',
              timeEnd: '',
              timeStart: 'Spring 2008'
            },
            /*
            {
              description: 'Graphic design, post-production. 2 days.',
              isOngoing: false,
              name: 'Arkitektkopia',
              timeEnd: '',
              timeStart: 'Spring 2008'
            }
             */
          ],
          title: 'Internships'
        },
        /*
        {
          list: [
            {
              description: 'Kitchen work. 3 weeks.',
              isOngoing: false,
              name: 'Tvedegård Preschool',
              timeEnd: '',
              timeStart: 'Summer 2006'
            },
            {
              description: 'Gardening. 3 weeks.',
              isOngoing: false,
              name: 'Charlottesborgs Camping',
              timeEnd: '',
              timeStart: 'Summer 2005'
            },
            {
              description: 'Nature conservation. 3 weeks.',
              isOngoing: false,
              name: 'Kristianstads Vattenrike Biosphere Reserve',
              timeEnd: '',
              timeStart: 'Summer 2004'
            }
          ],
          title: 'Holiday Jobs'
        },
         */
        {
          list: [
            {
              description: 'Scrum Master, Lead Front-End Developer.',
              isOngoing: true,
              name: 'National non-profit health organisation',
              timeEnd: '',
              timeStart: '08/02/2023'
            },
            {
              description: 'Host.',
              image: 'food2change.png',
              imageAlt: 'food2change',
              isOngoing: true,
              name: 'food2change',
              timeEnd: '19/07/2022',
              timeStart: '20/07/2021'
            },
            {
              description: 'Board member, graphic design.',
              isOngoing: true,
              name: 'National non-profit health organisation',
              timeEnd: '',
              timeStart: '23/03/2020'
            }
          ],
          title: 'Volunteering'
        },
        {
          list: [
            {
              description: 'Front-end development, graphic design.',
              isOngoing: false,
              name: 'Burburan.se',
              timeEnd: '',
              timeStart: 'Spring 2022'
            },
            {
              description: 'Graphic design, branding.',
              isOngoing: false,
              name: 'Freelance graphic designer based in Hong Kong',
              timeEnd: '26/12/2017',
              timeStart: '08/09/2017'
            },
            /*
            {
              description: 'Logotype design.',
              isOngoing: false,
              name: 'Kisma AB',
              timeEnd: '',
              timeStart: 'September 2017'
            },
             */
            {
              // description: 'Advertisement production, 4 advertisements.',
              description: 'Advertisement production.',
              isOngoing: false,
              name: 'Blue Bird Jazz Club',
              timeEnd: '',
              timeStart: '2008'
            }
          ],
          title: 'Other'
        }
      ],
      title: 'Work Experience'
    },
    {
      icon: 'school',
      list: [
        {
          list: [
            {
              description: '',
              image: 'umu.png',
              imageAlt: 'Umeå University',
              isOngoing: false,
              name: "Umeå University:<br /><em>Web Systems and Frameworks,</em> 7.5&nbsp;ECTS",
              timeEnd: '',
              timeStart: 'Autumn 2017'
            },
            {
              description: 'Game development for educational purposes with emphasis on psychology and intercultural communication. During the semester I also worked as Scrum Master and test leader of an international team developing a mobile serious game to educate teenagers in Ghana about prevalent health risks. I then travelled to Ghana in order to conduct user tests with the target audience.',
              image: 'hanze.png',
              imageAlt: 'Hanzehogeschool Groningen',
              isOngoing: false,
              name: "Hanzehogeschool Groningen:<br /><em>Game On,</em> 30&nbsp;ECTS (Erasmus+)",
              timeEnd: '',
              timeStart: 'Spring 2016'
            },
            {
              description: '',
              image: 'drivhuset.png',
              imageAlt: 'Drivhuset',
              isOngoing: false,
              name: "Drivhuset:<br /><em>Business start-up training</em>",
              timeEnd: '',
              timeStart: 'Autumn 2015'
            },
            {
              description: "Bachelor's thesis: A Study of Internal Communication in Distributed Agile Software Development &ndash; Challenges and Critical Success Factors.",
              image: 'kau.png',
              imageAlt: 'Karlstads universitet',
              isOngoing: false,
              name: "Karlstad University:<br /><em>Web and multimedia,</em> 180&nbsp;ECTS",
              timeEnd: '2017',
              timeStart: '2014'
            },
            {
              description: '',
              isOngoing: false,
              name: "Vuxenutbildningen i Kristianstad:<br /><em>Mathematics 2b,</em> 100&nbsp;credits",
              timeEnd: '',
              timeStart: 'Spring 2014'
            },
            {
              description: '',
              image: 'ju.png',
              imageAlt: 'Jönköping University',
              isOngoing: false,
              name: "Jönköping University:<br /><em>Photography &ndash; Visual communication,</em> 7.5&nbsp;ECTS",
              timeEnd: '',
              timeStart: 'Spring 2011'
            },
            {
              description: '',
              image: 'ju.png',
              imageAlt: 'Jönköping University',
              isOngoing: false,
              name: "Jönköping University:<br /><em>Image editing,</em> 7.5&nbsp;ECTS",
              timeEnd: '',
              timeStart: 'Autumn 2010'
            },
            {
              description: '',
              image: 'umu.png',
              imageAlt: 'Umeå University',
              isOngoing: false,
              name: "Umeå University:<br /><em>Italian, Beginner's course,</em> 15&nbsp;ECTS",
              timeEnd: '',
              timeStart: 'Autumn 2009'
            },
            {
              description: 'Media studies with a specialisation in graphic communication.',
              isOngoing: false,
              name: 'Christian 4:s gymnasium',
              timeEnd: '2008',
              timeStart: '2005'
            }
          ],
          title: 'Formal Education'
        },
        {
          list: [
            {
              description: "LinkedIn<br />URL: <a href=\"https://www.linkedin.com/learning/certificates/d13288e13736235fac40c4e0c89eab92b41127e486582d5a448633afbdf5cc9a\" rel=\"noreferrer\" target=\"_blank\">linkedin.com/learning/certificates/d13288e13736235fac40c4e0c89eab92b41127e486582d5a448633afbdf5cc9a</a>.",
              isOngoing: false,
              name: 'Building Generative AI Skills for Developers',
              timeEnd: '',
              timeStart: '19/09/2023'
            },
            {
              description: "Google Cloud Skills Boost<br />URL: <a href=\"https://www.cloudskillsboost.google/public_profiles/850d67aa-c65f-47ee-9380-2079b9df22eb\" rel=\"noreferrer\" target=\"_blank\">cloudskillsboost.google/public_profiles/850d67aa-c65f-47ee-9380-2079b9df22eb</a>.",
              isOngoing: false,
              name: 'Introduction to Generative AI Learning Path',
              timeEnd: '',
              timeStart: '07/09/2023'
            },
            {
              description: "AZ-900: Microsoft Azure Fundamentals, Microsoft<br />URL: <a href=\"https://learn.microsoft.com/api/credentials/share/sv-se/BurburanAndreas-7044/2AFC975A144639EB?sharingId=B9ABD59A036B424B\" rel=\"noreferrer\" target=\"_blank\">learn.microsoft.com/api/credentials/share/sv-se/BurburanAndreas-7044/2AFC975A144639EB?sharingId=B9ABD59A036B424B/</a>.",
              isOngoing: false,
              name: 'AZ-900: Microsoft Azure Fundamentals',
              timeEnd: '',
              timeStart: '23/08/2023'
            },
            {
              description: "Academind by Maximilian Schwarzmüller, Udemy<br />URL: <a href=\"https://www.udemy.com/certificate/UC-3bbdc6a6-5fcf-4106-a83e-af267b65f4c8/\" rel=\"noreferrer\" target=\"_blank\">udemy.com/certificate/UC-3bbdc6a6-5fcf-4106-a83e-af267b65f4c8/</a>.",
              isOngoing: false,
              name: "Next.js & React &ndash; The Complete Guide (incl. Two Paths!)",
              timeEnd: '',
              timeStart: '25/05/2023'
            },
            {
              description: "Scaled Agile, Inc.<br />URL: <a href=\"assets/certified-safe-5-agilist.pdf\" rel=\"noreferrer\" target=\"_blank\">certified-safe-5-agilist.pdf</a>.",
              isOngoing: false,
              name: 'Certified SAFe 5 Agilist',
              timeEnd: '02/10/2023',
              timeStart: '02/10/2022'
            },
            {
              description: "Academind by Maximilian Schwarzmüller, Udemy<br />URL: <a href=\"https://www.udemy.com/certificate/UC-6d9b2d20-ff52-4711-bd30-41f83c065eaa/\" rel=\"noreferrer\" target=\"_blank\">udemy.com/certificate/UC-6d9b2d20-ff52-4711-bd30-41f83c065eaa/</a>.",
              isOngoing: false,
              name: "Understanding TypeScript &ndash; 2022 Edition",
              timeEnd: '',
              timeStart: '26/06/2022'
            },
            {
              description: "Academind by Maximilian Schwarzmüller, Udemy<br />URL: <a href=\"https://www.udemy.com/certificate/UC-1VES0BP9\" rel=\"noreferrer\" target=\"_blank\">udemy.com/certificate/UC-1VES0BP9</a>.",
              isOngoing: false,
              name: "Progressive Web Apps (PWA) &ndash; The Complete Guide",
              timeEnd: '',
              timeStart: '21/08/2019'
            },
            {
              description: "Academind by Maximilian Schwarzmüller, Udemy<br />URL: <a href=\"https://www.udemy.com/certificate/UC-YG0QVROK\" rel=\"noreferrer\" target=\"_blank\">udemy.com/certificate/UC-YG0QVROK</a>.",
              isOngoing: false,
              name: "React Native &ndash; The Practical Guide",
              timeEnd: '',
              timeStart: '16/05/2019'
            },
            {
              description: "Academind by Maximilian Schwarzmüller, Udemy<br />URL: <a href=\"https://www.udemy.com/certificate/UC-F8298DV3\" rel=\"noreferrer\" target=\"_blank\">udemy.com/certificate/UC-F8298DV3</a>.",
              isOngoing: false,
              name: "React &ndash; The Complete Guide (incl. Hooks, React Router, Redux)",
              timeEnd: '',
              timeStart: '14/03/2019'
            }
          ],
          title: 'Certificates'
        },
        {
          list: [
            {
              description: '',
              image: 'erasmus.png',
              imageAlt: 'Erasmus+',
              isOngoing: false,
              name: 'Nominated as "the BEST ERASMUS TALENT of 2017"',
              timeEnd: '',
              timeStart: '2017'
            },
            {
              description: '',
              image: 'kau.png',
              imageAlt: 'Karlstads universitet',
              isOngoing: false,
              name: '"Buddy" for exchange students at Karlstad University',
              timeEnd: '',
              timeStart: 'Spring 2015'
            }
          ],
          title: 'Other'
        }
      ],
      title: 'Education'
    }
    /*
    {
      icon: 'more_horiz',
      list: [
        {
          name: 'Moved to Malmö, Sweden',
          timeStart: '26/02/2021'
        },
        {
          name: 'Moved to Stockholm, Sweden',
          timeStart: '17/05/2018'
        },
        {
          name: 'Moved to Kristianstad, Sweden',
          timeStart: '26/12/2017'
        },
        {
          name: 'Moved to Hong Kong',
          timeStart: '08/09/2017'
        },
        {
          name: 'Moved to Dublin, Irland',
          timeStart: '27/06/2017'
        },
        {
          name: 'Moved to Taipei, Taiwan',
          timeStart: '04/02/2017'
        },
        {
          name: 'Moved to Hong Kong',
          timeStart: '31/08/2016'
        },
        {
          name: 'Moved to Groningen, the Netherlands',
          timeStart: '27/01/2016'
        },
        {
          name: 'Moved to Karlstad, Sweden',
          timeStart: '22/08/2014'
        },
        {
          name: 'I was born',
          timeStart: '11/12/1989'
        }
      ],
      title: 'Övriga livserfarenheter'
    }
     */
  ],
  hobbies: {
    icon: 'hiking',
    list: [
      // 'Board games',
      'Chess',
      // 'Couchsurfing',
      // 'Cycling',
      'Gaming',
      // 'Meditation',
      'Minimalism',
      'Movies and series',
      'Muay Thai',
      // 'Teaching Swedish',
      'Travelling',
      'Volunteering',
      'Web development'
    ],
    title: 'Hobbies'
  },
  skills: [
    {
      icon: 'neurology',
      list: [
        /*
        {
          level: 70,
          skill: 'Agile project management'
        },
         */
        {
          level: 100,
          // skill: 'Agile software development'
          skill: 'Agile'
        },
        {
          level: 80,
          skill: 'Back-end Development'
        },
        {
          level: 100,
          // skill: 'Block Element Modifier (BEM)'
          skill: 'BEM'
        },
        {
          level: 85,
          skill: 'Business Model Canvas'
        },
        {
          level: 90,
          skill: 'DevOps'
        },
        {
          level: 30,
          skill: 'ER model'
        },
        {
          level: 100,
          skill: 'Front-end Development'
        },
        {
          level: 65,
          skill: 'Gamification'
        },
        {
          level: 80,
          skill: 'Graphic design'
        },
        /*
        {
          level: 80,
          skill: 'Image editing'
        },
        {
          level: 70,
          skill: 'MBTI'
        },
         */
        {
          level: 100,
          skill: 'Personas'
        },
        /*
        {
          level: 80,
          skill: 'Requirements management'
        },
         */
        {
          level: 100,
          skill: 'Responsive web design'
        },
        {
          level: 100,
          skill: 'Scrum'
        },
        {
          level: 75,
          // skill: 'Search engine optimisation (SEO)'
          skill: 'SEO'
        },
        /*
        {
          level: 80,
          skill: 'Testing'
        },
        {
          level: 40,
          skill: 'Traditional project management'
        },
         */
        {
          level: 30,
          skill: 'UML 2.0'
        },
        {
          level: 80,
          skill: 'UX design'
        }
      ],
      title: 'General Skills'
    },
    {
      icon: 'layers',
      list: [
        {
          level: 55,
          // skill: 'Intershop Commerce Platform'
          skill: 'Intershop'
        },
        {
          level: 50,
          skill: 'Microsoft Azure'
        },
        {
          level: 75,
          skill: 'WordPress'
        }
      ],
      title: 'Platforms'
    },
    {
      icon: 'javascript',
      list: [
        {
          level: 100,
          skill: 'AJAX'
        },
        {
          level: 85,
          skill: 'Angular'
        },
        {
          level: 75,
          skill: 'axios'
        },
        {
          level: 100,
          skill: 'ECMAScript 5'
        },
        {
          level: 100,
          skill: 'ECMAScript 6'
        },
        {
          level: 100,
          skill: 'ECMAScript 7'
        },
        {
          level: 95,
          skill: 'ECMAScript 8'
        },
        {
          level: 90,
          skill: 'ECMAScript 9'
        },
        /*
        {
          level: 80,
          skill: 'Fetch API'
        },
         */
        {
          level: 75,
          skill: 'i18next'
        },
        {
          level: 100,
          skill: 'JavaScript'
        },
        {
          level: 80,
          skill: 'Jest'
        },
        {
          level: 85,
          skill: 'jQuery'
        },
        {
          level: 40,
          skill: 'jQuery Mobile'
        },
        {
          level: 70,
          skill: 'jQuery UI'
        },
        {
          level: 30,
          skill: 'Leaflet'
        },
        {
          level: 30,
          skill: 'Mapbox GL JS'
        },
        {
          level: 70,
          skill: 'Next.js'
        },
        {
          level: 100,
          skill: 'PropTypes'
        },
        {
          level: 30,
          // skill: 'Progressive web apps (PWA)'
          skill: 'PWA'
        },
        {
          level: 95,
          skill: 'React'
        },
        {
          level: 35,
          skill: 'React Native'
        },
        {
          level: 60,
          skill: 'React Query'
        },
        {
          level: 85,
          skill: 'React Router'
        },
        {
          level: 75,
          skill: 'react-i18next'
        },
        {
          level: 95,
          skill: 'Redux'
        },
        {
          level: 95,
          skill: 'Redux Toolkit'
        },
        {
          level: 30,
          skill: 'redux-thunk'
        },
        /*
        {
          level: 25,
          skill: 'Svelte'
        },
        {
          level: 25,
          skill: 'SvelteKit'
        },
         */
        {
          level: 85,
          skill: 'TypeScript'
        }
      ],
      title: 'JavaScript'
    },
    {
      icon: 'brush',
      list: [
        {
          level: 85,
          skill: 'Bootstrap'
        },
        {
          level: 100,
          skill: 'CSS 2.1'
        },
        {
          level: 100,
          skill: 'CSS 3'
        },
        {
          level: 100,
          skill: 'Font Awesome'
        },
        {
          level: 80,
          skill: 'Less'
        },
        {
          level: 65,
          skill: 'MUI'
        },
        {
          level: 90,
          skill: 'Sass'
        },
        {
          level: 85,
          skill: 'styled-components'
        },
        {
          level: 40,
          skill: 'Tailwind CSS'
        }
      ],
      title: 'Styling'
    },
    {
      icon: 'code',
      list: [
        {
          level: 35,
          skill: 'Canvas API'
        },
        {
          level: 80,
          skill: 'EJS'
        },
        {
          level: 80,
          skill: 'Handlebars.js'
        },
        {
          level: 100,
          skill: 'HTML 4.01'
        },
        {
          level: 100,
          skill: 'HTML5'
        },
        {
          level: 100,
          skill: 'JSX'
        },
        {
          level: 95,
          skill: 'Markdown'
        },
        /*
        {
          level: 25,
          skill: 'microformats2'
        },
         */
        {
          level: 20,
          skill: 'SMIL'
        },
        {
          level: 70,
          skill: 'SVG'
        },
        {
          level: 100,
          skill: 'XHTML'
        },
        {
          level: 100,
          skill: 'XML'
        }
      ],
      title: 'Mark-up'
    },
    {
      icon: 'dns',
      list: [
        /*
        {
          level: 20,
          skill: 'C'
        },
         */
        {
          level: 30,
          skill: 'C#'
        },
        {
          level: 75,
          skill: 'Express.js'
        },
        {
          level: 30,
          skill: 'Java'
        },
        {
          level: 60,
          skill: 'Node.js'
        },
        {
          level: 70,
          skill: 'PHP'
        }
      ],
      title: 'Server-side Programming'
    },
    {
      icon: 'database',
      list: [
        {
          level: 80,
          skill: 'GeoJSON'
        },
        {
          level: 20,
          skill: 'GraphQL'
        },
        {
          level: 100,
          skill: 'JSON'
        },
        {
          level: 45,
          skill: 'MySQL'
        },
        {
          level: 85,
          skill: 'REST'
        },
        {
          level: 50,
          skill: 'SQL'
        },
        {
          level: 85,
          skill: 'YAML'
        }
      ],
      title: 'Data'
    },
    {
      icon: 'construction',
      list: [
        {
          level: 50,
          skill: 'bash'
        },
        {
          level: 100,
          skill: 'ChatGPT'
        },
        {
          level: 30,
          skill: 'Docker'
        },
        {
          level: 75,
          skill: 'ESLint'
        },
        {
          level: 75,
          skill: 'Git'
        },
        {
          level: 75,
          skill: 'GitHub Actions'
        },
        {
          level: 100,
          skill: 'GitHub Copilot'
        },
        {
          level: 50,
          skill: 'Google Analytics'
        },
        {
          level: 90,
          skill: 'Google DevTools'
        },
        /*
        {
          level: 35,
          skill: 'Kubernetes'
        },
         */
        {
          level: 90,
          // skill: 'Node Package Manager (npm)'
          skill: 'npm'
        },
        {
          level: 65,
          skill: 'Postman'
        },
        {
          level: 50,
          skill: 'SendGrid'
        },
        /*
        {
          level: 10,
          skill: 'SimpleTest'
        },
         */
        {
          level: 80,
          skill: 'Storybook'
        },
        {
          level: 80,
          skill: 'Vite'
        },
        {
          level: 40,
          skill: 'webpack'
        },
        {
          level: 90,
          skill: 'yarn'
        }
      ],
      title: 'Tools'
    },
    {
      icon: 'preview',
      list: [
        /*
        {
          level: 55,
          skill: 'Adobe Animate'
        },
         */
        {
          level: 80,
          skill: 'Adobe Camera Raw'
        },
        {
          level: 80,
          skill: 'Adobe Illustrator'
        },
        {
          level: 80,
          skill: 'Adobe InDesign'
        },
        {
          level: 80,
          skill: 'Adobe Photoshop'
        },
        {
          level: 90,
          skill: 'Adobe XD'
        },
        {
          level: 100,
          skill: 'Atomic design'
        },
        /*
        {
          level: 20,
          // skill: 'Autodesk 3D Studio Max'
          skill: 'Autodesk 3Ds Max'
        },
         */
        {
          level: 90,
          skill: 'Figma'
        },
        /*
        {
          level: 70,
          skill: 'Google SketchUp'
        },
         */
        {
          level: 90,
          skill: 'Zeplin'
        }
      ],
      title: 'UX & Design'
    },
    {
      icon: 'handshake',
      list: [
        {
          level: 80,
          skill: 'Atlassian Confluence'
        },
        {
          level: 80,
          skill: 'Atlassian Jira'
        },
        {
          level: 90,
          skill: 'Atlassian Trello'
        },
        {
          level: 100,
          skill: 'Microsoft Teams'
        },
        {
          level: 95,
          skill: 'Slack'
        }
      ],
      title: 'Collaboration Software'
    },
    /*
    {
      icon: 'laptop',
      list: [
        {
          level: 50,
          skill: 'ADJob'
        },
        {
          level: 50,
          skill: 'Google AdWords'
        },
        {
          level: 100,
          skill: 'iOS'
        },
        {
          level: 40,
          skill: 'Linux'
        },
        {
          level: 100,
          skill: 'macOS'
        },
        {
          level: 50,
          skill: 'Microsoft Excel'
        },
        {
          level: 60,
          skill: 'Microsoft PowerPoint'
        },
        {
          level: 70,
          skill: 'Microsoft Windows'
        },
        {
          level: 70,
          skill: 'Microsoft Word'
        }
      ],
      title: 'Software'
    },
     */
    {
      icon: 'translate',
      list: [
        {
          level: 30,
          skill: 'Dutch'
        },
        /*
        {
          level: 5,
          skill: 'Cantonese (studying)'
        },
         */
        {
          level: 100,
          skill: 'English'
        },
        {
          level: 20,
          skill: 'German'
        },
        {
          level: 20,
          skill: 'Interlingua'
        },
        {
          level: 20,
          skill: 'Italian'
        },
        {
          level: 100,
          skill: 'Swedish'
        },
        {
          level: 30,
          skill: 'Toki Pona'
        }
      ],
      title: 'Language Skills'
    }
  ],
  strengths: {
    list: [
      {
        strength: 'Learner',
        url: 'https://www.gallup.com/cliftonstrengths/en/252293/learner-theme.aspx'
      },
      {
        strength: 'Futuristic',
        url: 'https://www.gallup.com/cliftonstrengths/en/252248/futuristic-theme.aspx'
      },
      {
        strength: 'Command',
        url: 'https://www.gallup.com/cliftonstrengths/en/252176/command-theme.aspx'
      },
      {
        strength: 'Strategic',
        url: 'https://www.gallup.com/cliftonstrengths/en/252350/strategic-theme.aspx'
      },
      {
        strength: 'Input',
        url: 'https://www.gallup.com/cliftonstrengths/en/252278/input-theme.aspx'
      },
      {
        strength: 'Intellection',
        url: 'https://www.gallup.com/cliftonstrengths/en/252284/intellection-theme.aspx'
      },
      {
        strength: 'Analytical',
        url: 'https://www.gallup.com/cliftonstrengths/en/252152/analytical-theme.aspx'
      },
      {
        strength: 'Focus',
        url: 'https://www.gallup.com/cliftonstrengths/en/252239/focus-theme.aspx'
      },
      {
        strength: 'Self-Assurance',
        url: 'https://www.gallup.com/cliftonstrengths/en/252332/self-assurance-theme.aspx'
      },
      {
        strength: 'Ideation',
        url: 'https://www.gallup.com/cliftonstrengths/en/252260/ideation-theme.aspx'
      }
    ],
    title: 'Gallup CliftonStrengths<sup>&reg;</sup>'
  }
};

export default cv;
