import styled from 'styled-components';

import theme from '../../../theme/theme';

export const SContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 5vh;

  ${theme.breakpoints.forTabletPortraitUp} {
    margin-bottom: ${theme.lengths(6)};
  }

  ${theme.breakpoints.forTabletLandscapeUp} {
    margin-bottom: 0;
    margin-right: ${theme.lengths(4)};
  }

  ${theme.breakpoints.forDesktopUp} {
    margin-right: ${theme.lengths(16)};
  }
`;

export const SFrame = styled.div`
  border: ${theme.lengths(0.5)} solid ${theme.colors.blueLighter};
  overflow: hidden;
  transform: skew(-0.5deg, -1deg);

  ${theme.breakpoints.forTabletPortraitUp} {
    border: ${theme.lengths(0.75)} solid ${theme.colors.blueLighter};
  }

  ${theme.breakpoints.forTabletLandscapeUp} {
    border: ${theme.lengths(1)} solid ${theme.colors.blueLighter};
    transform: skew(-0.75deg, -1.5deg);
  }
`;

export const SImageBase = styled.img`
  display: flex;
`;

export const SImageSquare = styled(SImageBase)`
  transform: skew(0.5deg, 1deg) scale(1.05); // scale is an approximation
  height: 10vh;

  ${theme.breakpoints.forTabletPortraitUp} {
    height: 12vh;
  }

  ${theme.breakpoints.forTabletLandscapeUp} {
    display: none;
  }
`;

export const SImagePortrait = styled(SImageBase)`
  display: none;

  ${theme.breakpoints.forTabletLandscapeUp} {
    display: inline;
    height: 25vh;
    transform: skew(0.75deg, 1.5deg) scale(1.05); // scale is an approximation
  }

  ${theme.breakpoints.forDesktopUp} {
    height: auto;
    max-width: 600px;
    width: 20vw;
  }
`;
