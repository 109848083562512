import styled from 'styled-components';

import theme from '../../../theme/theme';

export const SContainer = styled.div`
  display: inline-grid;
  gap: ${theme.lengths(2.5)};
  grid-template-columns: repeat(3, 1fr);
  margin-left: auto;
  margin-right: auto;

  ${theme.breakpoints.forTabletPortraitUp} {
    gap: ${theme.lengths(4)};
    grid-template-columns: repeat(4, 1fr);
  }

  ${theme.breakpoints.forDesktopLargeUp} {
    gap: 2vh;
  }
`;

export const SMySuperpowers = styled.div`
  line-height: 140%;
  margin-top: 5vh;
  text-align: center;
  white-space: nowrap;

  ${theme.breakpoints.forMobileOnly} {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: 80vw;
  }

  ${theme.breakpoints.forTabletPortraitUp} {
    margin-top: ${theme.lengths(6)};
  }

  ${theme.breakpoints.forTabletLandscapeUp} {
    margin-top: ${theme.lengths(10)};
  }

  ${theme.breakpoints.forDesktopUp} {
    margin-top: 6vh;
  }
`;

export const SText = styled.div`
  color: ${theme.colors.white};
  font-weight: 200;
  margin-bottom: ${theme.lengths(6)};
  margin-bottom: 3.6vh;

  ${theme.breakpoints.forMobileOnly} {
    display: none;
  }

  &,
  * {
    font-size: ${theme.fontSizes.elephant};
    font-size: 3.375vh;
  }
`;
