const ls = {
  biography: {
    imageAlt: 'Andreas Burburan',
    imageTitle: 'Andreas Burburan',
    text: '"Senior frontend-utvecklare, nyfiken problemlösare, genomtrevlig medarbetare. Redo att implementera engagerande användar&shy;upplevelser som maximerar kundens värde. Självgående lagspelare med god kommunikations&shy;förmåga och tvär&shy;funktionell know-how. Trivs med att kontinuerligt lära mig och coacha kollegor i en värderings&shy;styrd kultur som uppmuntrar till ett growth mindset samt idéutbyte. Konnässör av best practice för effektivitet och hög&shy;kvalitativ kod. Beprövad erfarenhet av att leda frontend-projekt och designa arkitekturlösningar för Accenture-kunder."'
  },
  common: {
    email: 'E-post',
    linkedIn: 'LinkedIn'
  },
  footer: {
    // cta: 'Vill du komma i kontakt med mig? Kanske du har ett spännande projekt som du tror skulle passa mig &ndash; eller bara en liten fråga?'
    cta: 'Vill du komma i kontakt med mig?'
  },
  head: {
    author: 'Andreas Burburan',
    description: 'Senior frontend-utvecklare, nyfiken problemlösare och självgående lagspelare redo att implementera engagerande användarupplevelser som maximerar kundens värde.',
    keywords: 'frontend-utvecklare, webbutvecklare, utvecklare, programmerare',
    title: 'Andreas Burburan | Senior frontend-utvecklare i Malmö',
  },
  header: {
    biography: 'Biografi',
    contact: 'Kontakt',
    english: 'English',
    englishAlt: 'english',
    experiences: 'Erfarenheter',
    logoAlt: 'logo',
    skills: 'Färdigheter',
    start: 'Start',
    svenska: 'Svenska',
    svenskaAlt: 'svenska'
  },
  hero: {
    greeting: 'Hej!',
    introduction1: 'Andreas Burburan här,',
    introduction2: 'senior ',
    introduction3: 'frontend-utvecklare',
    introduction4: 'baserad i Malmö.',
    mySuperpowers: 'Mina superkrafter 💪'
  },
  mySuperpowers: [
    {
      image: 'typescript.svg',
      text: 'TypeScript'
    },
    {
      image: 'react.svg',
      text: 'React.js'
    },
    {
      image: 'next.svg',
      text: 'Next.js'
    },
    {
      image: 'node.svg',
      text: 'Node.js'
    }
  ]
};

export default ls;
