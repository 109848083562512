import styled from 'styled-components';
import { Link } from 'react-scroll';

import theme from '../../../theme/theme';

interface ILabel {
  $active: string;
  $scrolled: string;
}

export const SLink = styled(Link)`
  color: ${theme.colors.white};
  font-size: 10vw;
  text-decoration: none;

  ${theme.breakpoints.forTabletPortraitUp} {
    font-size: 5vw;
  }

  ${theme.breakpoints.forTabletLandscapeUp} {
    font-size: ${theme.fontSizes.dog};
  }

  ${theme.breakpoints.forDesktopUp} {
    font-size: ${theme.fontSizes.lion};
  }
`;

export const SLabel = styled.label<ILabel>`
  cursor: pointer;
  display: inline-block;
  padding-bottom: ${theme.lengths(1)};

  ${theme.breakpoints.forTabletLandscapeUp} {
    padding: 0;
  }

  > * {
    ${({ $active }) => $active === 'true' && `
      border-bottom: 1px solid ${theme.colors.white};
      padding-bottom: ${theme.lengths(0.5)};
      transition: none;
    `}

    ${({ $scrolled }) => $scrolled === 'true' && `
      ${theme.breakpoints.forDesktopUp} {
        font-size: ${theme.fontSizes.dog};
      }
    `}
  }
`;

export const SMenuListItem = styled.li`
  padding-bottom: 7vw;

  ${theme.breakpoints.forTabletPortraitUp} {
    padding-bottom: 5vw;
  }

  ${theme.breakpoints.forTabletLandscapeUp} {
    display: inline-block;
    padding-bottom: 0;
  }

  &:not(:last-child) {
    ${theme.breakpoints.forTabletLandscapeUp} {
      padding-right: ${theme.lengths(3)};
    }

    ${theme.breakpoints.forDesktopUp} {
      padding-right: ${theme.lengths(4)};
    }
  }
`;
