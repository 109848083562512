import { SName } from './Name.styles';

import IName from './Name.interface';

const Name: React.FC<IName> = ({ name }) => {
  const htmlContent = typeof name === 'string' ? name : '';

  return (
    <SName dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
};

export default Name;
