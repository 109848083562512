import styled from 'styled-components';

import theme from '../../../theme/theme';

export const SCircle = styled.span`
  align-items: center;
  background-color: ${theme.colors.white};
  border-radius: 50%;
  display: inline-flex;
  height: 6vh;
  justify-content: center;
  // margin-bottom: ${theme.lengths(2.5)};
  margin-bottom: 1.5vh;
  max-height: 125px;
  max-width: 125px;
  width: 6vh;

  ${theme.breakpoints.forTabletPortraitUp} {
    // height: ${theme.lengths(13)};
    height: 7.75vh;
    // width: ${theme.lengths(13)};
    width: 7.75vh;
  }
`;

export const SImage = styled.img`
  height: 60%;
  width: 60%;
`;

export const SSuperpower = styled.div`
  align-items: center;
  color: ${theme.colors.white};
  display: inline-flex;
  flex-direction: column;
  // font-size: ${theme.fontSizes.lion};
  font-size: 2.1vh;
  font-weight: 200;

  &:last-child {
    ${theme.breakpoints.forMobileOnly} {
      display: none;
    }
  }
`;
