import { useTranslation } from 'react-i18next';

import { SContainer, SMySuperpowers, SText } from './MySuperpowers.styles';
import Superpower from '../Superpower/Superpower';

import ISuperpower from '../Superpower/Superpower.interface';

const MySuperpowers: React.FC = () => {
  const { t } = useTranslation();

  const list: ISuperpower[] = t('mySuperpowers', { returnObjects: true });

  return (
    <SMySuperpowers>
      <SText>
        {t('hero.mySuperpowers')}
      </SText>

      <SContainer>
        {
          list &&
          Array.isArray(list) &&
          list.map((superpower: ISuperpower, index: number) => {
            return (
              <Superpower
                image={superpower.image}
                key={index}
                text={superpower.text}
              />
            );
          })
        }
      </SContainer>
    </SMySuperpowers>
  );
};

export default MySuperpowers;
