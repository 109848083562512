import { useTranslation } from 'react-i18next';

import andreasSquare from '../../../assets/andreas-square.jpg';
import andreasPortrait from '../../../assets/andreas-portrait.jpg';

import { SContainer, SFrame, SImageSquare, SImagePortrait } from './Portrait.styles';

const Text: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SContainer>
      <SFrame>
        <SImageSquare
          alt={t('biography.imageAlt')}
          src={andreasSquare}
          title={t('biography.imageTitle')}
        />

        <SImagePortrait
          alt={t('biography.imageAlt')}
          src={andreasPortrait}
          title={t('biography.imageTitle')}
        />
      </SFrame>
    </SContainer>
  );
};

export default Text;
