import styled from 'styled-components';

import theme from '../../../theme/theme';

import Button from '../../../components/atoms/Button/Button';
import Icon from '../../../components/atoms/Icon/Icon';

export const SButtonContact = styled(Button)`
  background-color: ${theme.colors.white};
  height: ${theme.lengths(4)};
  width: ${theme.lengths(4)};

  &:not(:first-of-type) {
    margin-left: ${theme.lengths(2)};
  }

  ${theme.breakpoints.forTabletPortraitUp} {
    height: ${theme.lengths(5)};
    width: ${theme.lengths(5)};
  }
`;

export const SIcon = styled(Icon)`
  font-size: ${theme.fontSizes.cat};
  overflow: hidden;
`;

export const SImage = styled.img`
  height: ${theme.lengths(2)};
`;
