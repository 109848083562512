import styled from 'styled-components';

import theme from '../../../theme/theme';
import triangeln from '../../../assets/triangeln.jpg';

const headerHeight = '100px';
const headerHeightForDesktopLargeUp = '164px';
const headerHeightForTabletLandscapeUp = '125px';

export const SButtons = styled.div`
  ${theme.breakpoints.forMobileOnly} {
    margin-top: 5vh;
    text-align: center;
    width: 100%;
  }

  ${theme.breakpoints.forTabletPortraitUp} {
    bottom: ${theme.lengths(4)};
    position: absolute;
    right: ${theme.lengths(5)};
  }

  ${theme.breakpoints.forDesktopUp} {
    bottom: ${theme.lengths(4)};
  }
`;

export const SGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-template-rows: repeat(3, auto);
  z-index: 1;

  ${theme.breakpoints.forTabletLandscapeUp} {
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
  }

  > *:nth-child(1) { // Portrait
    ${theme.breakpoints.forDesktopUp} {
      grid-row: span 2;
    }
  }

  > *:nth-child(3) { // MySuperpowers
    ${theme.breakpoints.forTabletLandscapeUp} {
      grid-column: span 2;
    }

    ${theme.breakpoints.forDesktopUp} {
      grid-column: initial;
    }
  }
`;

export const SHero = styled.section`
  align-items: center;
  background-image: url(${triangeln});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: calc(100vh - ${headerHeight});
  height: calc(100svh - ${headerHeight});
  justify-content: center;
  min-height: 556px; // iPhone SE
  padding-top: ${headerHeight};

  ${theme.breakpoints.forTabletLandscapeUp} {
    height: calc(100vh - ${headerHeightForTabletLandscapeUp});
    height: calc(100svh - ${headerHeightForTabletLandscapeUp});
    padding-top: ${headerHeightForTabletLandscapeUp};
  }

  ${theme.breakpoints.forDesktopLargeUp} {
    height: calc(100vh - ${headerHeightForDesktopLargeUp});
    height: calc(100svh - ${headerHeightForDesktopLargeUp});
    padding-top: ${headerHeightForDesktopLargeUp};
  }

  &:before {
    background-color: ${theme.colors.blueDarker};
    content: '';
    display: block;
    height: calc(100vh);
    height: calc(100svh);
    min-height: 681px; // iPhone SE
    opacity: 0.9925;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;
