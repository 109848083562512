import styled from 'styled-components';

import theme from '../../../theme/theme';

export const SGreeting = styled.p`
  font-size: 4.815vh;
  font-weight: 600;
  line-height: 1;
  // margin-bottom: ${theme.lengths(3.5)};
  margin-bottom: 2.1vh;

  ${theme.breakpoints.forTabletPortraitUp} {
    // font-size: ${theme.fontSizes.whale};
    font-size: 5.35vh;
  }
`;

export const SContainer = styled.p`
  line-height: 140%;

  &,
  * {
    font-size: 3vh;

    ${theme.breakpoints.forTabletPortraitUp} {
      // font-size: ${theme.fontSizes.elephant};
      font-size: 3.375vh;
    }
  }
`;

export const SIntroduction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  &,
  * {
    color: ${theme.colors.white};
  }
`;
