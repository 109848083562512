import { useTranslation } from 'react-i18next';

import { SBiography,  SText } from './Biography.styles';
import Strengths from '../../../components/organisms/Strengths/Strengths';

const Biography: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div id="biography">
      <SBiography>
        <SText dangerouslySetInnerHTML={{ __html: t('biography.text') }} />
      </SBiography>

      <Strengths />
    </div>
  );
};

export default Biography;
