import { useSelector } from 'react-redux';

import { AppState } from '../../../store/appSlice';

import { SIcon, SOverlay } from './Overlay.styles';

const Overlay: React.FC = () => {
  const hasScrolled = useSelector((state: AppState) => state.hasScrolled);
  const isOpen = useSelector((state: AppState) => state.isOpen);

  return (
    <SOverlay
      $hasScrolled={hasScrolled}
      $isOpen={isOpen}
    >
      <SIcon
        name="close"
        $isOpen={isOpen}
      />
    </SOverlay>
  );
};

export default Overlay;
