import styled from 'styled-components';

import theme from '../../../theme/theme';
import quote from '../../../assets/quote.svg';

import Section from '../../../components/templates/Section/Section';

export const SBiography = styled(Section)`
  display: flex;
  justify-content: center;
`;

export const SText = styled.p`
  background-image: url(${quote});
  background-repeat: no-repeat;
  color: ${theme.colors.white};
  font-style: italic;
  max-width: 800px;
  text-align: center;

  ${theme.breakpoints.forMobileOnly} {
    background-position: 75% 35%;
    background-size: 125px;
    font-size: ${theme.fontSizes.cat};
  }

  ${theme.breakpoints.forTabletPortraitUp} {
    background-position: 75% ${theme.lengths(5)};
    background-size: 200px;
    font-size: ${theme.fontSizes.lion};
  }

  ${theme.breakpoints.forDesktopLargeUp} {
    justify-content: center;
  }
`;
