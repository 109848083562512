import styled from 'styled-components';

import theme from '../../../theme/theme';

interface IImage {
  $hasScrolled: boolean;
}

export const SImage = styled.img<IImage>`
  height: 50px;
  transition: transform 0.25s;
  vertical-align: middle;

  ${theme.breakpoints.forTabletLandscapeUp} {
    height: 75px;
  }

  ${theme.breakpoints.forDesktopLargeUp} {
    height: 100px;
  }

  &:hover {
    transform: scale(1.05);
  }

  ${({ $hasScrolled }) => $hasScrolled && `
    height: ${theme.lengths(4)};

    ${theme.breakpoints.forTabletLandscapeUp} {
      height: 50px;
    }
  `}
`;

export const SLink = styled.a`
  flex-grow: 1;
  order: 2;
  text-align: center;

  ${theme.breakpoints.forTabletLandscapeUp} {
    flex-grow: 0;
    order: 0;
  }
`;
