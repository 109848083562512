import styled from 'styled-components';

import theme from '../../../theme/theme';

interface IHeader {
  $hasScrolled: boolean;
}

export const SHeader = styled.header<IHeader>`
  align-items: center;
  background-color: ${theme.colors.blueDarkest};
  box-sizing: border-box;
  display: flex;
  padding: 25px;
  position: fixed;
  width: 100%;
  z-index: 2;

  &,
  * {
    transition: padding 0.25s;
  }

  ${theme.breakpoints.forDesktopLargeUp} {
    padding: ${theme.lengths(4)} ${theme.lengths(5)};
  }

  ${({ $hasScrolled }) => $hasScrolled && `
    padding: 10px 15px;

    ${theme.breakpoints.forTabletLandscapeUp} {
      padding: ${theme.lengths(2.5)};
    }
  `}
`;
