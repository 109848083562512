import { SDescription } from './Description.styles';

import IDescription from './Description.interface';

const Description: React.FC<IDescription> = ({ description }) => {
  const htmlContent = typeof description === 'string' ? description : '';

  return (
    <SDescription dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
};

export default Description;
