const fontSizes = {
  mouse: '0.9375rem;',  // 14px
  cat: '1.25rem',       // 18px
  dog: '1.5rem',        // 24px
  lion: '1.875rem',     // 30px
  hippo: '2.25rem',     // 36px
  elephant: '3rem',     // 48px
  whale: '4.75rem'      // 76px
};

export default fontSizes;
