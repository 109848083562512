import styled from 'styled-components';

import theme from '../../../theme/theme';

import Button from '../../../components/atoms/Button/Button';

interface IFlag {
  $hasScrolled: boolean;
}

export const SButtonLanguage = styled(Button)`
  &:not(:first-of-type) {
    margin-left: ${theme.lengths(2)};

    ${theme.breakpoints.forTabletLandscapeUp} {
      margin-left: ${theme.lengths(2.5)};
    }
  }
`;

export const SFlag = styled.img<IFlag>`
  height: ${theme.lengths(4)};
  width: ${theme.lengths(4)};

  ${theme.breakpoints.forTabletLandscapeUp} {
    height: ${theme.lengths(5)};
    width: ${theme.lengths(5)};
  }

  ${({ $hasScrolled }) => $hasScrolled && `
    height: ${theme.lengths(3)};
    width: ${theme.lengths(3)};

    ${theme.breakpoints.forTabletLandscapeUp} {
      height: ${theme.lengths(4)};
      width: ${theme.lengths(4)};
    }
  `}
`;
