const ls = {
  biography: {
    imageAlt: 'Andreas Burburan',
    imageTitle: 'Andreas Burburan',
    text: '"Senior Front-End Developer, curious problem solver, go-to enthusiastic person. Ready to implement engaging user experiences and maximise client impact. Self-motivated team player with good communication skills and cross-functional know-how. Enjoy continuous learning and coaching in a value-oriented culture that promotes a growth mindset and idea-sharing. Connoisseur of best practices for efficiency and high-quality code. Proven experience leading front-end projects and designing architecture solutions to Accenture clients."'
  },
  common: {
    email: 'Email',
    linkedIn: 'LinkedIn'
  },
  footer: {
    // cta: 'Do you want to get in touch with me? Maybe you have an exciting project that you think would suit me&mdash;or just a simple question?'
    cta: 'Do you want to get in touch with me?'
  },
  head: {
    author: 'Andreas Burburan',
    description: 'Senior Front-End Developer, curious problem solver and self-motivated team player ready to implement engaging user experiences and maximise client impact.',
    keywords: 'front-end developer, web developer, developer, programmer',
    title: 'Andreas Burburan | Senior Front-End Developer in Malmö, Sweden',
  },
  header: {
    biography: 'Biography',
    contact: 'Contact',
    english: 'English',
    englishAlt: 'english',
    experiences: 'Experiences',
    logoAlt: 'logo',
    skills: 'Skills',
    start: 'Start',
    svenska: 'Svenska',
    svenskaAlt: 'svenska'
  },
  hero: {
    greeting: 'Hi there!',
    introduction1: 'Andreas Burburan here,',
    introduction2: 'Senior ',
    introduction3: 'Front-End Developer',
    introduction4: 'based in Malmö, Sweden.',
    mySuperpowers: 'My superpowers 💪'
  },
  mySuperpowers: [
    {
      image: 'typescript.svg',
      text: 'TypeScript'
    },
    {
      image: 'react.svg',
      text: 'React.js'
    },
    {
      image: 'next.svg',
      text: 'Next.js'
    },
    {
      image: 'node.svg',
      text: 'Node.js'
    }
  ]
};

export default ls;
