import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import throttle from 'lodash.throttle';

import { AppState, setHasScrolled } from '../../../store/appSlice';

import { SHeader } from './Header.styles';
import Languages from '../../molecules/Languages/Languages';
import Logo from '../../atoms/Logo/Logo';
import Navigation from '../../molecules/Navigation/Navigation';

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const hasScrolled = useSelector((state: AppState) => state.hasScrolled);

  useEffect(() => {
    const handleScroll = throttle(() => {
      dispatch(setHasScrolled(window.pageYOffset > 0));
    }, 200);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [dispatch]);

  return (
    <SHeader $hasScrolled={hasScrolled}>
      <Logo />

      <Navigation />

      <Languages />
    </SHeader>
  );
};

export default Header;
