const cv = {
  experiences: [
    {
      icon: 'apartment',
      list: [
        {
          list: [
            {
              description: 'Digital Technology Developer, Lead Front-End Developer.',
              image: 'accenturesong.png',
              imageAlt: 'Accenture Song',
              isOngoing: true,
              name: 'Accenture Song',
              timeEnd: '',
              timeStart: '21/5 2018'
            },
            {
              description: 'Originalarbete, 3D-modellering, frontend-utveckling, search engine marketing (SEM) m.m. Sporadiska uppdrag utförda på begäran.',
              image: 'infab.svg',
              imageAlt: 'Infab',
              isOngoing: false,
              name: 'Infab',
              timeEnd: '15/6 2017',
              timeStart: '25/8 2014'
            },
            {
              description: 'Originalarbete, 3D-modellering, frontend-utveckling, search engine marketing (SEM) m.m.',
              image: 'quid.png',
              imageAlt: 'Quid Reklambyrå',
              isOngoing: false,
              name: 'Quid Reklambyrå',
              timeEnd: '22/8 2014',
              timeStart: '1/7 2013'
            },
            {
              description: "Innehållshantering, originalarbete, digital bildbehandling. 50&nbsp;%.",
              image: 'ahmans.png',
              imageAlt: 'Åhmans i Åhus',
              isOngoing: false,
              name: 'Åhmans i Åhus',
              timeEnd: '28/6 2013',
              timeStart: '1/3 2013'
            },
            {
              description: "Originalarbete, 3D-modellering, frontend-utveckling, search engine marketing (SEM) m.m. 50&nbsp;%.",
              image: 'quid.png',
              imageAlt: 'Quid Reklambyrå',
              isOngoing: false,
              name: 'Quid Reklambyrå',
              timeEnd: '31/5 2013',
              timeStart: '1/3 2013'
            },
            {
              description: 'Originalarbete, 3D-modellering, frontend-utveckling, search engine marketing (SEM) m.m.',
              image: 'quid.png',
              imageAlt: 'Quid Reklambyrå',
              isOngoing: false,
              name: 'Quid Reklambyrå',
              timeEnd: '28/2 2013',
              timeStart: '11/4 2011'
            }
          ],
          title: 'Anställningar'
        },
        {
          list: [
            {
              description: 'Frontend-utveckling, UX-design.',
              image: 'adapt.png',
              imageAlt: 'ADAPT Centre',
              isOngoing: false,
              name: "ADAPT Centre vid Trinity College Dublin: <em>Online Ethics Canvas</em>",
              timeEnd: '25/8 2017',
              timeStart: '3/7 2017'
            },
            {
              description: 'Originalarbete, 3D-modellering, frontend-utveckling, search engine marketing (SEM) m.m.',
              image: 'quid.png',
              imageAlt: 'Quid Reklambyrå',
              isOngoing: false,
              name: 'Quid Reklambyrå',
              timeEnd: '08/4 2011',
              timeStart: '28/9 2010'
            },
            /*
            {
              description: 'Montage, packning.',
              isOngoing: false,
              name: 'Kristianstad Lego AB',
              timeEnd: '31/8 2010',
              timeStart: '12/4 2010'
            },
             */
            {
              description: 'Originalarbete.',
              image: 'ksk.png',
              imageAlt: 'Kristianstad Studentkår',
              isOngoing: false,
              name: 'Kristianstad Studentkår vid Högskolan Kristianstad',
              timeEnd: '29/6 2009',
              timeStart: '10/6 2009'
            },
            {
              description: 'Originalarbete.',
              image: 'abk.png',
              imageAlt: 'AB Kristianstadsbyggen',
              isOngoing: false,
              name: 'AB Kristianstadsbyggen',
              timeEnd: '5/6 2009',
              timeStart: '26/5 2009'
            },
            /*
            {
              description: "Originalarbete, digitaltryck. 9 veckor utspridda under gymnasiegången samt 5&ndash;6 månader därefter.",
              isOngoing: false,
              name: 'Orkidén',
              timeEnd: '2008',
              timeStart: '2006'
            },
             */
            {
              description: 'Annonsproduktion. 1 vecka.',
              image: 'kb.png',
              imageAlt: 'Kristianstadsbladet',
              isOngoing: false,
              name: 'Kristianstadsbladet',
              timeEnd: '',
              timeStart: 'Våren 2008'
            },
            /*
            {
              description: 'Originalarbete, efterbehandling. 2 dagar.',
              isOngoing: false,
              name: 'Arkitektkopia',
              timeEnd: '',
              timeStart: 'Våren 2008'
            }
             */
          ],
          title: 'Praktik'
        },
        /*
        {
          list: [
            {
              description: 'Köksarbete. 3 veckor.',
              isOngoing: false,
              name: 'Tvedegårds förskola',
              timeEnd: '',
              timeStart: 'Sommaren 2006'
            },
            {
              description: 'Trädgårdsarbete. 3 veckor.',
              isOngoing: false,
              name: 'Charlottesborgs Camping',
              timeEnd: '',
              timeStart: 'Sommaren 2005'
            },
            {
              description: 'Naturvård. 3 veckor.',
              isOngoing: false,
              name: 'Kristianstads Vattenrike',
              timeEnd: '',
              timeStart: 'Sommaren 2004'
            }
          ],
          title: 'Feriejobb'
        },
        */
        {
          list: [
            {
              description: 'Scrum Master, Lead Front-End Developer.',
              isOngoing: true,
              name: 'Nationell ideell hälsoorganisation',
              timeEnd: '',
              timeStart: '8/2 2023'
            },
            {
              description: 'Värd.',
              image: 'food2change.png',
              imageAlt: 'food2change',
              isOngoing: true,
              name: 'food2change',
              timeEnd: '19/7 2022',
              timeStart: '20/7 2021'
            },
            {
              description: 'Styrelseledamot, grafisk design.',
              isOngoing: true,
              name: 'Nationell ideell hälsoorganisation',
              timeEnd: '',
              timeStart: '23/3 2020'
            }
          ],
          title: 'Volontärarbete'
        },
        {
          list: [
            {
              description: 'Frontend-utveckling, grafisk design.',
              isOngoing: false,
              name: 'Burburan.se',
              timeEnd: '',
              timeStart: 'Våren 2022'
            },
            {
              description: 'Grafisk design, branding.',
              isOngoing: false,
              name: 'Frilansdesigner baserad i Hongkong',
              timeEnd: '26/12 2017',
              timeStart: '8/9 2017'
            },
            /*
            {
              description: 'Logotypdesign.',
              isOngoing: false,
              name: 'Kisma AB',
              timeEnd: '',
              timeStart: 'September 2017'
            },
             */
            {
              // description: 'Annonsproduktion, 4 st annonser.',
              description: 'Annonsproduktion.',
              isOngoing: false,
              name: 'Blue Bird Jazz Club',
              timeEnd: '',
              timeStart: '2008'
            }
          ],
          title: 'Övrigt'
        }
      ],
      title: "Arbetslivs&shy;erfarenheter"
    },
    {
      icon: 'school',
      list: [
        {
          list: [
            {
              description: '',
              image: 'umu.png',
              imageAlt: 'Umeå universitet',
              isOngoing: false,
              name: "Umeå universitet:<br /><em>Webbsystem och ramverk,</em> 7,5&nbsp;hp",
              timeEnd: '',
              timeStart: 'Hösten 2017'
            },
            {
              description: 'Spelutveckling för utbildningsändamål med inriktning på psykologi och interkulturell kommunikation. Under terminen arbetade jag dessutom som Scrum Master och testledare i en internationell arbetsgrupp som utvecklade ett mobilspel för att utbilda ungdomar i Ghana om lokala hälsorisker. Jag reste samtidigt till Ghana för att utföra användartester med skolungdomar inom målgruppen.',
              image: 'hanze.png',
              imageAlt: 'Hanzehogeschool Groningen',
              isOngoing: false,
              name: "Hanzehogeschool Groningen:<br /><em>Game On,</em> 30&nbsp;hp (Erasmus+)",
              timeEnd: '',
              timeStart: 'Våren 2016'
            },
            {
              description: '',
              image: 'drivhuset.png',
              imageAlt: 'Drivhuset',
              isOngoing: false,
              name: "Drivhuset:<br /><em>Drivhusets starta-eget-utbildning</em>",
              timeEnd: '',
              timeStart: 'Hösten 2015'
            },
            {
              description: "Kandidatuppsats: En studie om intern kommunikation i distribuerad agil systemutveckling &ndash; Utmaningar och kritiska framgångsfaktorer.",
              image: 'kau.png',
              imageAlt: 'Karlstads universitet',
              isOngoing: false,
              name: "Karlstads universitet:<br /><em>Webb och multimedia,</em> 180&nbsp;hp",
              timeEnd: '2017',
              timeStart: '2014'
            },
            {
              description: '',
              isOngoing: false,
              name: "Vuxenutbildningen i Kristianstad:<br /><em>Matematik 2b,</em> 100&nbsp;p",
              timeEnd: '',
              timeStart: 'Våren 2014'
            },
            {
              description: '',
              image: 'ju.png',
              imageAlt: 'Jönköping University',
              isOngoing: false,
              name: "Jönköping University:<br /><em>Fotografi &ndash; Visuell kommunikation,</em> 7,5&nbsp;hp",
              timeEnd: '',
              timeStart: 'Våren 2011'
            },
            {
              description: '',
              image: 'ju.png',
              imageAlt: 'Jönköping University',
              isOngoing: false,
              name: "Jönköping University:<br /><em>Digital bildbehandling,</em> 7,5&nbsp;hp",
              timeEnd: '',
              timeStart: 'Hösten 2010'
            },
            {
              description: '',
              image: 'umu.png',
              imageAlt: 'Umeå universitet',
              isOngoing: false,
              name: "Umeå universitet:<br /><em>Italienska, Nybörjarkurs,</em> 15&nbsp;hp",
              timeEnd: '',
              timeStart: 'Hösten 2009'
            },
            {
              description: 'Medieprogrammet med inriktning i Grafisk kommunikation.',
              isOngoing: false,
              name: 'Christian 4:s gymnasium',
              timeEnd: '2008',
              timeStart: '2005',
            }
          ],
          title: 'Formell utbildning'
        },
        {
          list: [
            {
              description: "LinkedIn<br />URL: <a href=\"https://www.linkedin.com/learning/certificates/d13288e13736235fac40c4e0c89eab92b41127e486582d5a448633afbdf5cc9a\" rel=\"noreferrer\" target=\"_blank\">linkedin.com/learning/certificates/d13288e13736235fac40c4e0c89eab92b41127e486582d5a448633afbdf5cc9a</a>.",
              isOngoing: false,
              name: 'Building Generative AI Skills for Developers',
              timeEnd: '',
              timeStart: '19/9 2023'
            },
            {
              description: "Google Cloud Skills Boost<br />URL: <a href=\"https://www.cloudskillsboost.google/public_profiles/850d67aa-c65f-47ee-9380-2079b9df22eb\" rel=\"noreferrer\" target=\"_blank\">cloudskillsboost.google/public_profiles/850d67aa-c65f-47ee-9380-2079b9df22eb</a>.",
              isOngoing: false,
              name: 'Introduction to Generative AI Learning Path',
              timeEnd: '',
              timeStart: '7/9 2023'
            },
            {
              description: "AZ-900: Microsoft Azure Fundamentals, Microsoft<br />URL: <a href=\"https://learn.microsoft.com/api/credentials/share/sv-se/BurburanAndreas-7044/2AFC975A144639EB?sharingId=B9ABD59A036B424B\" rel=\"noreferrer\" target=\"_blank\">learn.microsoft.com/api/credentials/share/sv-se/BurburanAndreas-7044/2AFC975A144639EB?sharingId=B9ABD59A036B424B/</a>.",
              isOngoing: false,
              name: 'AZ-900: Microsoft Azure Fundamentals',
              timeEnd: '',
              timeStart: '23/8 2023'
            },
            {
              description: "Academind by Maximilian Schwarzmüller, Udemy<br />URL: <a href=\"https://www.udemy.com/certificate/UC-3bbdc6a6-5fcf-4106-a83e-af267b65f4c8/\" rel=\"noreferrer\" target=\"_blank\">udemy.com/certificate/UC-3bbdc6a6-5fcf-4106-a83e-af267b65f4c8/</a>.",
              isOngoing: false,
              name: "Next.js & React &ndash; The Complete Guide (incl. Two Paths!)",
              timeEnd: '',
              timeStart: '25/5 2023'
            },
            {
              description: "Scaled Agile, Inc.<br />URL: <a href=\"assets/certified-safe-5-agilist.pdf\" rel=\"noreferrer\" target=\"_blank\">certified-safe-5-agilist.pdf</a>.",
              isOngoing: false,
              name: 'Certified SAFe 5 Agilist',
              timeEnd: '2/10 2023',
              timeStart: '2/10 2022'
            },
            {
              description: "Academind by Maximilian Schwarzmüller, Udemy<br />URL: <a href=\"https://www.udemy.com/certificate/UC-6d9b2d20-ff52-4711-bd30-41f83c065eaa/\" rel=\"noreferrer\" target=\"_blank\">udemy.com/certificate/UC-6d9b2d20-ff52-4711-bd30-41f83c065eaa/</a>.",
              isOngoing: false,
              name: "Understanding TypeScript &ndash; 2022 Edition",
              timeEnd: '',
              timeStart: '26/6 2022'
            },
            {
              description: "Academind by Maximilian Schwarzmüller, Udemy<br />URL: <a href=\"https://www.udemy.com/certificate/UC-1VES0BP9\" rel=\"noreferrer\" target=\"_blank\">udemy.com/certificate/UC-1VES0BP9</a>.",
              isOngoing: false,
              name: "Progressive Web Apps (PWA) &ndash; The Complete Guide",
              timeEnd: '',
              timeStart: '21/8 2019'
            },
            {
              description: "Academind by Maximilian Schwarzmüller, Udemy<br />URL: <a href=\"https://www.udemy.com/certificate/UC-YG0QVROK\" rel=\"noreferrer\" target=\"_blank\">udemy.com/certificate/UC-YG0QVROK</a>.",
              isOngoing: false,
              name: "React Native &ndash; The Practical Guide",
              timeEnd: '',
              timeStart: '16/5 2019'
            },
            {
              description: "Academind by Maximilian Schwarzmüller, Udemy<br />URL: <a href=\"https://www.udemy.com/certificate/UC-F8298DV3\" rel=\"noreferrer\" target=\"_blank\">udemy.com/certificate/UC-F8298DV3</a>.",
              isOngoing: false,
              name: "React &ndash; The Complete Guide (incl. Hooks, React Router, Redux)",
              timeEnd: '',
              timeStart: '14/3 2019',
            }
          ],
          title: 'Certifikat'
        },
        {
          list: [
            {
              description: '',
              image: 'erasmus.png',
              imageAlt: 'Erasmus+',
              isOngoing: false,
              name: 'Nominerad till "the BEST ERASMUS TALENT of 2017"',
              timeEnd: '',
              timeStart: '2017'
            },
            {
              description: '',
              image: 'kau.png',
              imageAlt: 'Karlstads universitet',
              isOngoing: false,
              name: 'Fadder åt utbytesstudenter vid Karlstads universitet',
              timeEnd: '',
              timeStart: 'Våren 2015',
            }
          ],
          title: 'Övrigt'
        }
      ],
      title: 'Utbildning'
    }
    /*
    {
      icon: 'more_horiz',
      list: [
        {
          name: 'Flyttade till Malmö',
          timeStart: '26/2 2021'
        },
        {
          name: 'Flyttade till Stockholm',
          timeStart: '17/5 2018'
        },
        {
          name: 'Flyttade till Kristianstad',
          timeStart: '26/12 2017'
        },
        {
          name: 'Flyttade till Hongkong',
          timeStart: '8/9 2017'
        },
        {
          name: 'Flyttade till Dublin (Irland)',
          timeStart: '27/6 2017'
        },
        {
          name: 'Flyttade till Taipei (Taiwan)',
          timeStart: '4/2 2017'
        },
        {
          name: 'Flyttade till Hongkong',
          timeStart: '31/8 2016'
        },
        {
          name: 'Flyttade till Groningen (Nederländerna)',
          timeStart: '27/1 2016'
        },
        {
          name: 'Flyttade till Karlstad',
          timeStart: '22/8 2014'
        },
        {
          name: 'Jag föddes',
          timeStart: '11/12 1989'
        }
      ],
      title: 'Övriga livserfarenheter'
    }
     */
  ],
  hobbies: {
    icon: 'hiking',
    list: [
      // 'Brädspel',
      // 'Couchsurfing',
      // 'Cykling',
      'Filmer och serier',
      'Gaming',
      // 'Lära ut svenska',
      // 'Meditation',
      'Minimalism',
      'Muay Thai',
      'Resor',
      'Schack',
      'Volontärarbete',
      'Webbutveckling'
    ],
    title: 'Fritidsintressen'
  },
  skills: [
    {
      icon: 'neurology',
      list: [
        /*
        {
          level: 70,
          skill: 'Agil projektledning'
        },
         */
        {
          level: 100,
          skill: 'Agil systemutveckling'
        },
        {
          level: 80,
          skill: 'Backend-utveckling'
        },
        {
          level: 100,
          // skill: 'Block Element Modifier (BEM)'
          skill: 'BEM'
        },
        {
          level: 85,
          skill: 'Business Model Canvas'
        },
        {
          level: 90,
          skill: 'DevOps'
        },
        /*
        {
          level: 80,
          skill: 'Digital bildbehandling'
        },
         */
        {
          level: 30,
          skill: 'ER-modellen'
        },
        {
          level: 100,
          skill: 'Frontend-utveckling'
        },
        {
          level: 65,
          skill: 'Gamification'
        },
        {
          level: 80,
          skill: 'Grafisk design'
        },
        /*
        {
          level: 80,
          skill: 'Kravhantering'
        },
        {
          level: 70,
          skill: 'MBTI'
        },
         */
        {
          level: 100,
          skill: 'Personas'
        },
        {
          level: 100,
          skill: 'Responsiv webbdesign'
        },
        {
          level: 100,
          skill: 'Scrum'
        },
        {
          level: 75,
          // skill: 'Sökmotoroptimering (SEO)'
          skill: 'SEO'
        },
        /*
        {
          level: 80,
          skill: 'Testning'
        },
        {
          level: 40,
          skill: 'Traditionell projektledning'
        },
         */
        {
          level: 30,
          skill: 'UML 2.0'
        },
        {
          level: 80,
          skill: 'UX-design'
        }
      ],
      title: 'Generella färdigheter'
    },
    {
      icon: 'layers',
      list: [
        {
          level: 55,
          // skill: 'Intershop Commerce Platform'
          skill: 'Intershop'
        },
        {
          level: 50,
          skill: 'Microsoft Azure'
        },
        {
          level: 75,
          skill: 'WordPress'
        }
      ],
      title: 'Plattformar'
    },
    {
      icon: 'javascript',
      list: [
        {
          level: 100,
          skill: 'AJAX'
        },
        {
          level: 85,
          skill: 'Angular'
        },
        {
          level: 75,
          skill: 'axios'
        },
        {
          level: 100,
          skill: 'ECMAScript 5'
        },
        {
          level: 100,
          skill: 'ECMAScript 6'
        },
        {
          level: 100,
          skill: 'ECMAScript 7'
        },
        {
          level: 95,
          skill: 'ECMAScript 8'
        },
        {
          level: 90,
          skill: 'ECMAScript 9'
        },
        /*
        {
          level: 80,
          skill: 'Fetch API'
        },
         */
        {
          level: 75,
          skill: 'i18next'
        },
        {
          level: 100,
          skill: 'JavaScript'
        },
        {
          level: 80,
          skill: 'Jest'
        },
        {
          level: 85,
          skill: 'jQuery'
        },
        {
          level: 40,
          skill: 'jQuery Mobile'
        },
        {
          level: 70,
          skill: 'jQuery UI'
        },
        {
          level: 30,
          skill: 'Leaflet'
        },
        {
          level: 30,
          skill: 'Mapbox GL JS'
        },
        {
          level: 70,
          skill: 'Next.js'
        },
        {
          level: 100,
          skill: 'PropTypes'
        },
        {
          level: 30,
          // skill: 'Progressiva webbapplikationer (PWA)'
          skill: 'PWA'
        },
        {
          level: 95,
          skill: 'React'
        },
        {
          level: 35,
          skill: 'React Native'
        },
        {
          level: 60,
          skill: 'React Query'
        },
        {
          level: 85,
          skill: 'React Router'
        },
        {
          level: 75,
          skill: 'react-i18next'
        },
        {
          level: 95,
          skill: 'Redux'
        },
        {
          level: 95,
          skill: 'Redux Toolkit'
        },
        {
          level: 30,
          skill: 'redux-thunk'
        },
        /*
        {
          level: 25,
          skill: 'Svelte'
        },
        {
          level: 25,
          skill: 'SvelteKit'
        },
         */
        {
          level: 85,
          skill: 'TypeScript'
        }
      ],
      title: 'JavaScript'
    },
    {
      icon: 'brush',
      list: [
        {
          level: 85,
          skill: 'Bootstrap'
        },
        {
          level: 100,
          skill: 'CSS 2.1'
        },
        {
          level: 100,
          skill: 'CSS 3'
        },
        {
          level: 100,
          skill: 'Font Awesome'
        },
        {
          level: 80,
          skill: 'Less'
        },
        {
          level: 65,
          skill: 'MUI'
        },
        {
          level: 90,
          skill: 'Sass'
        },
        {
          level: 85,
          skill: 'styled-components'
        },
        {
          level: 40,
          skill: 'Tailwind CSS'
        }
      ],
      title: 'Styling'
    },
    {
      icon: 'code',
      list: [
        {
          level: 35,
          skill: 'Canvas API'
        },
        {
          level: 80,
          skill: 'EJS'
        },
        {
          level: 80,
          skill: 'Handlebars.js'
        },
        {
          level: 100,
          skill: 'HTML 4.01'
        },
        {
          level: 100,
          skill: 'HTML5'
        },
        {
          level: 100,
          skill: 'JSX'
        },
        {
          level: 95,
          skill: 'Markdown'
        },
        /*
        {
          level: 25,
          skill: 'microformats2'
        },
         */
        {
          level: 20,
          skill: 'SMIL'
        },
        {
          level: 70,
          skill: 'SVG'
        },
        {
          level: 100,
          skill: 'XHTML'
        },
        {
          level: 100,
          skill: 'XML'
        }
      ],
      title: 'Markup'
    },
    {
      icon: 'dns',
      list: [
        /*
        {
          level: 20,
          skill: 'C'
        },
         */
        {
          level: 30,
          skill: 'C#'
        },
        {
          level: 75,
          skill: 'Express.js'
        },
        {
          level: 30,
          skill: 'Java'
        },
        {
          level: 60,
          skill: 'Node.js'
        },
        {
          level: 70,
          skill: 'PHP'
        }
      ],
      title: 'Serverprogramming'
    },
    {
      icon: 'database',
      list: [
        {
          level: 80,
          skill: 'GeoJSON'
        },
        {
          level: 20,
          skill: 'GraphQL'
        },
        {
          level: 100,
          skill: 'JSON'
        },
        {
          level: 45,
          skill: 'MySQL'
        },
        {
          level: 85,
          skill: 'REST'
        },
        {
          level: 50,
          skill: 'SQL'
        },
        {
          level: 85,
          skill: 'YAML'
        }
      ],
      title: 'Data'
    },
    {
      icon: 'construction',
      list: [
        {
          level: 50,
          skill: 'bash'
        },
        {
          level: 100,
          skill: 'ChatGPT'
        },
        {
          level: 30,
          skill: 'Docker'
        },
        {
          level: 75,
          skill: 'ESLint'
        },
        {
          level: 75,
          skill: 'Git'
        },
        {
          level: 75,
          skill: 'GitHub Actions'
        },
        {
          level: 100,
          skill: 'GitHub Copilot'
        },
        {
          level: 50,
          skill: 'Google Analytics'
        },
        {
          level: 90,
          skill: 'Google DevTools'
        },
        /*
        {
          level: 35,
          skill: 'Kubernetes'
        },
         */
        {
          level: 90,
          // skill: 'Node Package Manager (npm)'
          skill: 'npm'
        },
        {
          level: 65,
          skill: 'Postman'
        },
        {
          level: 50,
          skill: 'SendGrid'
        },
        /*
        {
          level: 10,
          skill: 'SimpleTest'
        },
         */
        {
          level: 80,
          skill: 'Storybook'
        },
        {
          level: 80,
          skill: 'Vite'
        },
        {
          level: 40,
          skill: 'webpack'
        },
        {
          level: 90,
          skill: 'yarn'
        }
      ],
      title: 'Verktyg'
    },
    {
      icon: 'preview',
      list: [
        /*
        {
          level: 55,
          skill: 'Adobe Animate'
        },
         */
        {
          level: 80,
          skill: 'Adobe Camera Raw'
        },
        {
          level: 80,
          skill: 'Adobe Illustrator'
        },
        {
          level: 80,
          skill: 'Adobe InDesign'
        },
        {
          level: 80,
          skill: 'Adobe Photoshop'
        },
        {
          level: 90,
          skill: 'Adobe XD'
        },
        {
          level: 100,
          skill: 'Atomic design'
        },
        /*
        {
          level: 20,
          // skill: 'Autodesk 3D Studio Max'
          skill: 'Autodesk 3Ds Max'
        },
        */
        {
          level: 90,
          skill: 'Figma'
        },
        /*
        {
          level: 70,
          skill: 'Google SketchUp'
        },
         */
        {
          level: 90,
          skill: 'Zeplin'
        }
      ],
      title: 'UX & design'
    },
    {
      icon: 'handshake',
      list: [
        {
          level: 80,
          skill: 'Atlassian Confluence'
        },
        {
          level: 80,
          skill: 'Atlassian Jira'
        },
        {
          level: 90,
          skill: 'Atlassian Trello'
        },
        {
          level: 100,
          skill: 'Microsoft Teams'
        },
        {
          level: 95,
          skill: 'Slack'
        }
      ],
      title: 'Samarbetsverktyg'
    },
    /*
    {
      icon: 'laptop',
      list: [
        {
          level: 50,
          skill: 'ADJob'
        },
        {
          level: 50,
          skill: 'Google AdWords'
        },
        {
          level: 100,
          skill: 'iOS'
        },
        {
          level: 40,
          skill: 'Linux'
        },
        {
          level: 100,
          skill: 'macOS'
        },
        {
          level: 50,
          skill: 'Microsoft Excel'
        },
        {
          level: 60,
          skill: 'Microsoft PowerPoint'
        },
        {
          level: 70,
          skill: 'Microsoft Windows'
        },
        {
          level: 70,
          skill: 'Microsoft Word'
        }
      ],
      title: 'Mjukvaror'
    },
     */
    {
      icon: 'translate',
      list: [
        {
          level: 100,
          skill: 'Engelska'
        },
        {
          level: 20,
          skill: 'Interlingua'
        },
        {
          level: 20,
          skill: 'Italienska'
        },
        /*
        {
          level: 5,
          skill: 'Kantonesiska (studerar)'
        },
         */
        {
          level: 30,
          skill: 'Nederländska'
        },
        {
          level: 100,
          skill: 'Svenska'
        },
        {
          level: 30,
          skill: 'Toki pona'
        },
        {
          level: 20,
          skill: 'Tyska'
        }
      ],
      title: 'Språk'
    }
  ],
  strengths: {
    list: [
      {
        strength: 'Learner',
        url: 'https://www.gallup.com/cliftonstrengths/en/252293/learner-theme.aspx'
      },
      {
        strength: 'Futuristic',
        url: 'https://www.gallup.com/cliftonstrengths/en/252248/futuristic-theme.aspx'
      },
      {
        strength: 'Command',
        url: 'https://www.gallup.com/cliftonstrengths/en/252176/command-theme.aspx'
      },
      {
        strength: 'Strategic',
        url: 'https://www.gallup.com/cliftonstrengths/en/252350/strategic-theme.aspx'
      },
      {
        strength: 'Input',
        url: 'https://www.gallup.com/cliftonstrengths/en/252278/input-theme.aspx'
      },
      {
        strength: 'Intellection',
        url: 'https://www.gallup.com/cliftonstrengths/en/252284/intellection-theme.aspx'
      },
      {
        strength: 'Analytical',
        url: 'https://www.gallup.com/cliftonstrengths/en/252152/analytical-theme.aspx'
      },
      {
        strength: 'Focus',
        url: 'https://www.gallup.com/cliftonstrengths/en/252239/focus-theme.aspx'
      },
      {
        strength: 'Self-Assurance',
        url: 'https://www.gallup.com/cliftonstrengths/en/252332/self-assurance-theme.aspx'
      },
      {
        strength: 'Ideation',
        url: 'https://www.gallup.com/cliftonstrengths/en/252260/ideation-theme.aspx'
      }
    ],
    title: 'Gallup CliftonStrengths<sup>&reg;</sup>'
  }
};

export default cv;
