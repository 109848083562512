import { useTranslation } from 'react-i18next';

import { SContainer, SGreeting, SIntroduction } from './Introduction.styles';

const Text: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SIntroduction>
      <SGreeting>
        {t('hero.greeting')}
      </SGreeting>

      <SContainer>
        {/* First line */}
        {t('hero.introduction1')}
        <br />

        {/* Second line */}
        {t('hero.introduction2')}
        <strong>
          {t('hero.introduction3')}
        </strong>
        <br />

        {/* Third line */}
        {t('hero.introduction4')}
      </SContainer>
    </SIntroduction>
  );
};

export default Text;
