import { SProgress } from './Progress.styles';

import IProgress from './Progress.interface';

const Progress: React.FC<IProgress> = ({ level }) => {
  return (
    <SProgress $level={level} />
  );
};

export default Progress;
