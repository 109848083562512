import { SCircle, SImage, SSuperpower } from './Superpower.styles';

import ISuperpower from '../../molecules/Superpower/Superpower.interface';

const Superpower: React.FC<ISuperpower> = ({ image, text }) => {
  return (
    <SSuperpower>
      <SCircle>
        <SImage
          alt={text}
          src={'assets/' + image}
        />
      </SCircle>

      {text}
    </SSuperpower>
  );
};

export default Superpower;
